import { AttachFile } from '@mui/icons-material';
import DownloadingIcon from '@mui/icons-material/Downloading';

import { CardHeader, Dialog, DialogActions, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { HotfileState, TableHotFileStatus } from 'hotfile-api';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
type HotFolderPublishListProps = {
    publish: () => void;
    clear: () => void;
    retry: () => void;
    status: TableHotFileStatus[];
};

const HotFolderPublishList = ({ status, publish, clear, retry }: HotFolderPublishListProps) => {
    const { t } = useTranslation();
    const [awaiting, setAwaiting] = useState<readonly TableHotFileStatus[]>([]);
    const [pending, setPending] = useState<readonly TableHotFileStatus[]>([]);
    const [inprogress, setInProgress] = useState<readonly TableHotFileStatus[]>([]);
    const [error, setError] = useState<readonly TableHotFileStatus[]>([]);
    const [uploaded, setUploaded] = useState<readonly TableHotFileStatus[]>([]);

    const [publishDialogOpen, setPublishDialogOpen] = useState<boolean>(false);
    const [clearDialogOpen, setClearDialogOpen] = useState<boolean>(false);

    const handlePublishDialog = (ok: boolean) => {
        if (ok) {
            publish();
        }
        setPublishDialogOpen(false);
    };

    const handleClearDialogOpen = (ok: boolean) => {
        if (ok) {
            clear();
        }
        setClearDialogOpen(false);
    };

    useEffect(() => {
        if (status) {
            setAwaiting(status.filter((st) => st.state === HotfileState.None));
            setPending(status.filter((st) => st.state === HotfileState.Pending));
            setInProgress(status.filter((st) => st.state === HotfileState.InProgress));
            setError(status.filter((st) => st.state === HotfileState.Error));
            setUploaded(status.filter((st) => st.state === HotfileState.Uploaded));
        }
    }, [status]);

    const customList = (title: React.ReactNode, items: readonly TableHotFileStatus[]) => (
        <Card>
            <CardHeader sx={{ px: 2, py: 1 }} title={title} />
            <Divider />
            <List
                sx={{
                    height: 230,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value: TableHotFileStatus, index: number) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem key={index} role="listitem">
                            <ListItemIcon>
                                <AttachFile />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.fileName}`} />
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );

    return (
        <Grid alignItems="flex-start" sx={{ maxWidth: 1000 }}>
            <Grid item>
                <Grid spacing={2} container direction="column" justifyContent="left">
                    <Grid item>
                        {customList(
                            <Grid direction="row" container>
                                <Grid item flex={1}>
                                    {t('publish.awaitPublish')}
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="contained"
                                        size="small"
                                        onClick={() => setClearDialogOpen(true)}
                                        disabled={!(awaiting?.length > 0)}
                                        aria-label="publish"
                                        color="secondary"
                                    >
                                        {t('publish.clear', 'Clear')}
                                    </Button>
                                    <Dialog
                                        open={clearDialogOpen}
                                        onClose={() => handleClearDialogOpen(false)}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {t('publish.dialogDescription')}
                                        </DialogTitle>
                                        <DialogActions>
                                            <Button onClick={() => handleClearDialogOpen(false)}>
                                                {t('publish.cancel')}
                                            </Button>
                                            <Button onClick={() => handleClearDialogOpen(true)} autoFocus>
                                                {t('publish.clear')}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Grid>
                            </Grid>,
                            awaiting,
                        )}
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={() => setPublishDialogOpen(true)}
                                disabled={!(awaiting?.length > 0)}
                                aria-label="publish"
                            >
                                {t('publish.publish', 'Publish')} <DownloadingIcon sx={{ marginLeft: 1 }} />
                            </Button>
                            <Dialog
                                open={publishDialogOpen}
                                onClose={() => handlePublishDialog(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{t('publish.dialogDescription')}</DialogTitle>
                                <DialogActions>
                                    <Button onClick={() => handlePublishDialog(false)}>{t('publish.cancel')}</Button>
                                    <Button onClick={() => handlePublishDialog(true)} autoFocus>
                                        {t('publish.publish')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Grid>
                    {pending?.length > 0 && <Grid item>{customList(t('publish.pending'), pending)}</Grid>}
                    {inprogress?.length > 0 && <Grid item>{customList(t('publish.inprogress'), inprogress)}</Grid>}
                    {error?.length > 0 && (
                        <Grid item>
                            {customList(
                                <Grid direction="row" container>
                                    <Grid item flex={1}>
                                        {t('publish.error')}
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            sx={{ my: 0.5 }}
                                            variant="contained"
                                            size="small"
                                            onClick={() => retry()}
                                            disabled={false}
                                            aria-label="publish"
                                            color="secondary"
                                        >
                                            {t('publish.retry', 'Retry')}
                                        </Button>
                                    </Grid>
                                </Grid>,
                                error,
                            )}
                        </Grid>
                    )}
                    {uploaded?.length > 0 && <Grid item>{customList(t('publish.uploaded'), uploaded)}</Grid>}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HotFolderPublishList;
